















































































































































































import { Vue } from 'vue-property-decorator';
import { roomService, fileService} from '@/_services/';
import DropableCell from '@/components/DropableCell.vue';
import { settings } from '@/variables';
import EventBus from '@/variables'
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import {CheckIn, Reservation, RoomElement, Floor } from '@/types';

Vue.use(BootstrapVueIcons);

export default Vue.extend({
  name: "App",
  data() {
    return {
      transProps: {
        name: 'flip-list'
      },
      fieldsStart: [
        /*{ key: "floor", label: "Stockwerk", stickyColumn: true, }, */
        { key: "name", label: "Zimmer", stickyColumn: true, } ,
      ],
      fields: [ { key: "floor", label: "Stockwerk", stickyColumn: true, } ],
      holidays: [
        {key: "2022-01-01"},
        {key: "2022-05-01"},
        {key: "2022-10-26"},
        {key: "2022-11-01"},
      ],
      clientFields: [
        { key: "fullname", label: "Drag-me" },
        { key: "artistName", label: "Künstlername", sortable: "true"},
        { key: "firstName", label: "Vorname", sortable: "true", },
        { key: "lastName", label: "Nachname", sortable: "true", },
      ],
      floors: [] as any[],
      nameMonth1: "",
      searchString: "",
      numMonth1: 0,
      nameMonth2: "",
      numMonth2: 0,
      nameMonth3: "",
      numMonth3: 0,
      hideMonth3: false,
      imageSource: "",
      items: [],
      checkInData: [] as CheckIn[],
      newRoom: {
        floor: "0",
        number: 1,
        name: 'Zimmer 1',
      },
      infoModal: {
          id: 'reservationInfo',
          title: '',
          content: '',
          data: {},
          isNew: false
      },
      clients: [],
      selectedReservation: {} as Reservation,
      overnightStatus: true,
      selectedCheckIn: {} as CheckIn,
      today: new Date(),
      monthNames: settings.months,
      eventHub: EventBus,
      selectedRoom: "",
      numberOfDays: 10,
      daysBeforeNow: 3,
    };
  },
  created():void {
    this.loadCheckIns();
    this.reCreate();
  },
  mounted():void {
    console.log("mounted");
    this.eventHub.$on('reservationInfo', (reservation: Reservation) => {
      this.selectedReservation= reservation;
      this.$root.$emit('bv::show::modal', "reservationInfo")
      this.loadClientImage();
      this.items.forEach((roomElem: RoomElement) => {
        if (roomElem._key==reservation.room) {
          this.selectedRoom= roomElem.name;
        }
      });
    });
  },
  methods: {
    showCheckinDialog(checkIn: CheckIn) {
      this.selectedCheckIn= this.findCheckin(checkIn);
      this.overnightStatus = this.selectedCheckIn.overNight=='Y';
      console.log("set overnightStatus to " + this.overnightStatus + ", selectedCheckin: " + JSON.stringify(this.selectedCheckIn));
      this.$root.$emit('bv::show::modal', "checkinDialog")
    },
    doCheckIn(dat: any) {
      let record= {
        _from: settings.dbschema+"_Client/"+dat.clientKey,
        _to: settings.dbschema+"_Room/"+dat.room,
        checkInDate: dat.date,
        remark1: "checkin reported by "
      }
      console.log("checkin " + JSON.stringify(record));
      roomService.checkIn(record).then((result: any) => {
        this.loadCheckIns().then(() => {
          // data already assigned to member 
          this.reCreate();
        });
        return result;
      });
    },
    doCheckOut(dat: any):void {
      console.log("checkout");
      let result = {} as CheckIn;
      let roomStr = settings.dbschema+"_Room/" + dat.room;
      this.checkInData.forEach((checkin: CheckIn) => {
        if (checkin._to==roomStr) {
          if (checkin.checkInDate<=dat.date) {
            if (checkin.checkOutDate==null || checkin.checkOutDate>=dat.date) {
              result= checkin;
              return;
            }
          }
        }
      });
      if (result._key!=null) {
        console.log("found checkin " + result._key);
        result.checkOutDate= dat.date;
        roomService.updateCheckin(result).then((result: any) => {
          this.reCreate();
        });
      }
    },
    updateCheckin():void {
      console.log("update clicked");
      this.selectedCheckIn.overNight= this.overnightStatus?"Y":"N";
      roomService.updateCheckin(this.selectedCheckIn).then((result: any) => {
        this.reCreate();
      });
    },
    deleteCheckin(): void {
      console.log("delete clicked " + JSON.stringify(this.selectedCheckIn));
      roomService.deleteCheckin(this.selectedCheckIn).then((result: any) => {
        this.$root.$emit('bv::hide::modal', "checkinDialog");
        this.loadCheckIns();
        this.reCreate();
      });
    },
    loadCheckIns() {
      return roomService.getCheckIns(new Date(), 45).then((result: CheckIn[]) => {
        this.checkInData= result;
        console.log("received checkIns " + JSON.stringify(this.checkInData));
      });
    },
    loadFloors() {
      return roomService.getFloors().then((result: Floor[]) => {
        this.floors= [];
        result.forEach(floor => {
          this.floors.push({ value: floor.floorNumber, text: floor.floorName})
        });
        console.log("received checkIns " + JSON.stringify(this.checkInData));
      });
    },
    findCheckin(data: CheckIn): CheckIn {
      let result = {} as CheckIn;
      let roomStr= settings.dbschema+"_Room/" + data.room;
      let clientStr= settings.dbschema+"_Client/" + data.clientKey;
      this.checkInData.forEach((checkin: CheckIn) => {
        if (checkin._to==roomStr && checkin._from==clientStr) {
          if (data.date!=null && checkin.checkInDate<=data.date) {
            if (checkin.checkOutDate==null || checkin.checkOutDate>=data.date) {
              result= checkin;
            }
          }
        }
      });
      return result;
    },
    isBooked(data: CheckIn): boolean {
      let result = this.findCheckin(data);
      let bool= (result._key!=null);
      return bool;
    },
    canBook(dat: any): boolean {
      let selDat= new Date(dat.date);
      let now= new Date();
      return (dat.clientKey>0 && now.getTime()>=selDat.getTime());
    },
    isToday(dat: any): boolean {
      let selDat= new Date(dat.date);
      let now= new Date();
      return (now.toISOString().substring(0,10)==selDat.toISOString().substring(0,10));
    },
    floorName(nr: string): string {
      let result= "Stockwerk " + nr;
      this.floors.forEach(floor => {
        if (nr==floor.value) {
          result= floor.text;
        }  
      });
      return result;
    },
    backgroundColor(floor: string, item: any): string {
      if (item!=null) {
        let dateObj= new Date(item.date);
        if (dateObj.getDay()==6  || dateObj.getDay()==0) {
          return "#eecccc";
        }
        let found= "";
        this.holidays.forEach(holyDay => {
          // console.log("comparing " + holyDay.key + " with " + item.date);
          if (item.date.substring(0,10)==holyDay.key) {
            found= "#eecccc";
          }
        });
        if (found!="") return found;
      }
      if (floor=="0") {
        return "#ffeeee";
      } else if (floor=="1") {
        return "#eeffee";
      } else {
        return "#eeeeff";
      }
    },
    isWeekEnd(dateStr: string): boolean {
      let parts= dateStr.split("|");
      if (parts.length==3) {
        return parts[0]=="SO" || parts[0]=="SA";
      }
      return false;
    },
    resetInfoModal():void {

      console.log("reset dialog")
    },
    reCreate():void {
      console.log("Creating planning vue");
      if (this.$route.query.startDay) {
        let millis= Number.parseInt(""+this.$route.query.startDay);
        this.today= new Date(millis);
      } else {
        this.today= new Date(new Date().getTime() - this.daysBeforeNow * 1000*24*60*60);
      }
      this.setStartDay(this.today);
    },
    startChange(days: number): void {
      this.today= new Date(this.today.getTime()+days*24*60*60*1000);
      // router.replace({path: "planning"});
      this.$router.replace( {path: "rooms", query: {startDay: ""+this.today.getTime() }});
      this.$router.go(0);
    },
    setStartDay(today: Date): void {
      this.buildFieldList(today);
      roomService.getAllReservations(today, 45).then((rooms: any) => {
        // console.log("received room list " + JSON.stringify(rooms));
        this.items= rooms;
      });
    },
    loadClientImage():void  {
      fileService.getFromClient(""+this.selectedReservation.clientKey, "portrait").then((response: any) => {
          if (response.data!="") {
              console.log("received image data");
              this.imageSource= response.data;
          }
      });
    },
      buildFieldList(today: Date):void {
        this.fields= this.fieldsStart;
        for (let i=1; i<=this.numberOfDays; i++) {
            let day= new Date(today.getTime()+(i-1)*1000*24*60*60);
            let date= day.getDate();
            let weekday= settings.weekdays[day.getDay()];
            let labelStr= ""+weekday+"|"+date+"|"+day.toDateString();
            this.fields.push({key: "day"+i, label: labelStr, stickyColumn: false});
        }
        this.nameMonth1= settings.months[today.getMonth()];
        let found1= false;
        this.numMonth1= this.numberOfDays; // if not found
        for (let i=1; i<=this.numberOfDays && !found1; i++) {
          let day= new Date(today.getTime()+1000*24*60*60*i);
          if (settings.months[day.getMonth()] != this.nameMonth1) {
            this.numMonth1=i;
            this.nameMonth2= settings.months[day.getMonth()];
            found1= true;
            let found2= false;
            for (let j=1; j<=35 && !found2; j++) {
              let day= new Date(today.getTime()+1000*24*60*60*(i+j));
              if (settings.months[day.getMonth()] != this.nameMonth2) {
                this.numMonth2= j;
                this.nameMonth3= settings.months[day.getMonth()];
                this.numMonth3= this.numberOfDays-(i+j);
                found2= true;
              }
            }
          }
        }
      },
      startDrag(event: DragEvent, item: Record<string, unknown>):void {
        console.log("drag started with client " + item._key);
        if (event.dataTransfer!=null) {
          event.dataTransfer.dropEffect= 'copy';
          event.dataTransfer.effectAllowed = 'copy';
          console.log(JSON.stringify(item));
          event.dataTransfer.setData("client", JSON.stringify(item));
        }
      }
  },
  components: {
    DropableCell,
  }
});
